import styled from 'styled-components';
import { zIndex } from '../../../../../helpers/constants/zIndexValues';
import { Spacing } from '../../../../../styles/theme/types';
import { getSpacing } from '../../../../../helpers/functions/styling';

export const Backdrop = styled.div<{ isOpen: boolean }>`
  position: fixed;
  z-index: ${zIndex.modal};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    visibility 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -webkit-transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    visibility 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  will-change: opacity, visibility;
  display: flex;
`;

export const Container = styled.div<{
  isOpen: boolean;
}>`
  background: ${({ theme }) => theme.palette.main.white};
  border-radius: 4px;
  width: 100%;
  max-width: 480px;
  min-width: 320px;
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate3d(0, ${({ isOpen }) => (isOpen ? '0' : '100vh')}, 0);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  margin: auto;
  height: 80%;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

export const Background = styled.div<{
  backgroundColor: string;
  hasPadding: boolean;
  padding: Spacing;
}>`
  background: ${({ backgroundColor }) => backgroundColor};
  width: 100%;
  height: 100%;
  padding: ${({ hasPadding, padding }) =>
    hasPadding ? getSpacing(padding) : '0px'};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    border-radius: 4px;
    @media only screen and (max-width: 768px) {
      border-radius: 0;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => `${theme.palette.main.turquoise}80`};
    border-radius: 4px;
    @media only screen and (max-width: 768px) {
      border-radius: 0;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.main.turquoise};
    border-radius: 4px;
    @media only screen and (max-width: 768px) {
      border-radius: 0;
    }
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => `${theme.palette.main.turquoise}CC`};
  }
`;

export const ChildrenContainer = styled.div`
  width: 100%;
  flex-grow: 1;
`;

export const CloseIconRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
