import EmptyPackagesState from 'components/dumb/atomics/EmptyPackagesState';
import SearchBar from 'components/dumb/atomics/SearchBar';
import TabBar from 'components/dumb/atomics/TabBar';
import SearchFiltersBar from 'components/dumb/atomics/SearchFiltersBar';
import { useTranslation } from 'react-i18next';
import { Experience } from 'uiModels/interfaces';
import { SearchFilters } from 'helpers/constants/enums';
import { useEffect, useState } from 'react';
import AccountFilterResult, { User } from './AccountFilterResult';
import ExperienceCard, { ActionButton } from '../../atomics/ExperienceCard';
import ExperienceSkeleton from '../../atomics/Skeletons/ExperienceSkeleton';
import * as SC from './styles';
import {
  backgroundContainerClass,
  menuBarClass,
} from '../../../../helpers/constants/styling';
import IconButton from '../../atomics/IconButton';
import FiltersIcon from '../../../../assets/icons/buttonIcons/FiltersIcon';
import theme from '../../../../styles/theme/classic';
import FiltersModal from '../FiltersModal';
import { Option } from '../../atomics/DropdownInput';
import { ExperienceUrlFilters } from '../FiltersModal/FiltersForm';
import { domainTabs } from './domainTabs';
import { getExperienceCardType } from '../../../../helpers/functions/experience/experienceCardType';

interface Props {
  experiences?: Experience[];
  filters: ExperienceUrlFilters;
  users?: User[];
  onShowExminderProfileClick?: (id: string) => void;
  onOpenFilters: (open: boolean) => void;
  onSearchText: CallbackFunction;
  onDomainFilter: CallbackFunction;
  onSelectSearchFilter: CallbackFunction;
  onUserFilterResultClick: CallbackFunction;
  requestIsLoading: boolean;
  isExminder: boolean;
  onCreateExperienceClick: CallbackFunction;
  onCreateAuctionClick: CallbackFunction;
  onTurnOnNotifications: CallbackFunction;
  isLoading: boolean;
  currentSearchFilter: SearchFilters;
  isTextSearched: boolean;
  isOnboardingSkipped: boolean;
  onBuildProfile: CallbackFunction;
  onSelectedCountry: CallbackFunction;
  onSearchedCity: CallbackFunction;
  onApplyFilters: CallbackFunction;
  onClearFilters: CallbackFunction;
  countries: Option[];
  cities: Option[];
  languages: Option[];
  searchText: string;
  searchFilter: string;
}

export default function ExperienceFeedPageLayout(props: Props) {
  const {
    experiences,
    filters,
    users,
    onShowExminderProfileClick,
    onOpenFilters,
    onSearchText,
    onDomainFilter,
    onSelectSearchFilter,
    onUserFilterResultClick,
    requestIsLoading,
    isExminder,
    onCreateAuctionClick,
    onCreateExperienceClick,
    onTurnOnNotifications,
    isLoading,
    currentSearchFilter,
    isTextSearched,
    isOnboardingSkipped,
    onBuildProfile,
    onSelectedCountry,
    onSearchedCity,
    onApplyFilters,
    onClearFilters,
    countries,
    cities,
    languages,
    searchText,
    searchFilter,
  } = props;
  const { t } = useTranslation();

  const [openFilters, setOpenFilters] = useState(false);

  useEffect(() => {
    onOpenFilters(openFilters);
  }, [openFilters]);

  const filtersCount = () => {
    let count = 0;

    count += filters.experienceType ? 1 : 0;
    count += filters.minPrice ? 1 : 0;
    count += filters.maxPrice ? 1 : 0;

    count += filters.currency ? 1 : 0;
    count += filters.country ? 1 : 0;
    count += filters.city ? 1 : 0;
    count += filters.language ? 1 : 0;
    count += filters.domain ? 1 : 0;

    return count;
  };

  const mappedUserFilterResults = users?.map((user: User) => (
    <AccountFilterResult
      key={user.id}
      user={user}
      onAccountFilterResultClick={() => {
        onUserFilterResultClick(user.role, user.id, user.slug);
      }}
    />
  ));

  const normalizedExperiences = experiences?.map((experience: Experience) => (
    <ExperienceCard
      id={experience.id}
      key={experience.id}
      title={experience.title}
      presentationPhoto={experience.presentationPhoto}
      location={experience.city}
      domain={experience.domain}
      startDate={experience.startTime}
      endDate={experience.endTime}
      price={experience.price}
      currency={experience.currency}
      discount={experience.discountPercent}
      cardType={getExperienceCardType(experience)}
      typeOfExperience={experience.type}
      exminder={{
        id: experience.exminder?.id,
        firstName: experience.exminder?.firstName,
        lastName: experience.exminder?.lastName,
        profilePicture: experience.exminder?.profilePicture,
        workExperience: experience.exminder?.workExperience,
      }}
      actionButton={ActionButton.EXPLORE}
      href={experience.href}
      onShowExminderProfileClick={() =>
        onShowExminderProfileClick &&
        onShowExminderProfileClick(
          experience.slug?.contentCreatorFullName ?? ''
        )
      }
    />
  ));

  const renderExperiences = () => {
    const skeletonLoader = (
      <>
        <ExperienceSkeleton showUser />
        <ExperienceSkeleton showUser />
        <ExperienceSkeleton showUser />
      </>
    );

    if (!isLoading && normalizedExperiences?.length === 0) {
      return (
        <EmptyPackagesState
          isExminder={isExminder}
          onCreateAuctionClick={onCreateAuctionClick}
          onCreateExperienceClick={onCreateExperienceClick}
          onTurnOnNotifications={onTurnOnNotifications}
          isOnboardingSkipped={isOnboardingSkipped}
          onBuildProfile={onBuildProfile}
        />
      );
    }

    return (
      <>
        {normalizedExperiences}
        {isLoading && skeletonLoader}
      </>
    );
  };

  return (
    <SC.Container>
      <SC.Background className={menuBarClass}>
        <SC.NavigationBarContainer className={backgroundContainerClass}>
          <SC.FilteringContainer>
            <SC.SearchBarContainer>
              <SearchBar
                placeholder={t(`experienceFeed.searchExperiencesPeople`)}
                onSearch={onSearchText}
                value={searchText}
              />
            </SC.SearchBarContainer>
            <SC.FiltersButton>
              {filtersCount() > 0 && (
                <SC.FiltersCountBadge>{filtersCount()}</SC.FiltersCountBadge>
              )}
              <IconButton
                color={theme.palette.main.white}
                icon={<FiltersIcon />}
                iconSize={{ width: '24px', height: '24px' }}
                rotateIcon={90}
                onClick={() => {
                  setOpenFilters(true);
                }}
              />
            </SC.FiltersButton>
          </SC.FilteringContainer>
          {filtersCount() > 0 ? null : (
            <SC.NavigationBarContent>
              <SC.TabBarContainer>
                <TabBar
                  onDomainFilter={onDomainFilter}
                  buttons={domainTabs()}
                />
              </SC.TabBarContainer>
            </SC.NavigationBarContent>
          )}
        </SC.NavigationBarContainer>
        {isTextSearched && (
          <SearchFiltersBar
            onSelectSearchFilter={onSelectSearchFilter}
            searchFilter={searchFilter}
          />
        )}
        <SC.MainContent>
          {currentSearchFilter === SearchFilters.EXPERIENCES && (
            <SC.ExperiencesContainer>
              {renderExperiences()}
            </SC.ExperiencesContainer>
          )}
          {currentSearchFilter === SearchFilters.ACCOUNTS && (
            <SC.UsersContainer>{mappedUserFilterResults}</SC.UsersContainer>
          )}
        </SC.MainContent>
      </SC.Background>
      <FiltersModal
        isOpen={openFilters}
        filters={filters}
        requestIsLoading={requestIsLoading}
        countries={countries}
        cities={cities}
        languages={languages}
        handleSelectedCountry={onSelectedCountry}
        handleSearchedCity={onSearchedCity}
        onApplyFilters={onApplyFilters}
        onClearFilters={onClearFilters}
        onClose={() => {
          setOpenFilters(false);
        }}
      />
    </SC.Container>
  );
}
