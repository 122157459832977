import classic from '../../styles/theme/classic';
import TypeChecker from '../classes/TypeChecker';
import { Spacing } from '../../styles/theme/types';

export const pxToRem = (px: number | string): string => {
  const baseUnits: string = classic.typography.fontSize;

  if (!px) return baseUnits;

  let pxUnits: number;

  if (TypeChecker.isString(px)) {
    const pxString = px as string;

    pxUnits = parseFloat(pxString);

    if (Number.isNaN(pxUnits)) {
      throw new Error('Invalid px input param');
    }
  } else {
    pxUnits = px;
  }

  const parsedBaseUnits: number = parseFloat(baseUnits);
  const documentFontSizeInPx: number = parseFloat(
    getComputedStyle(document.documentElement).fontSize
  );

  return `${
    ((documentFontSizeInPx + pxUnits) / documentFontSizeInPx) * parsedBaseUnits
  }rem`;
};

export const getSpacing = (spacing: Spacing): string => {
  if (typeof spacing === 'number') {
    return `${spacing}px`;
  }
  if (typeof spacing === 'object') {
    const { top, right, bottom, left } = spacing;
    return `${top}px ${right}px ${bottom}px ${left}px`;
  }
  throw new Error('Invalid spacing format');
};
