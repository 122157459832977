import CloseModalIcon from 'assets/icons/CloseModalIcon';
import {
  ButtonHTMLAttributes,
  MouseEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as SC from './styles';
import ButtonWrapper from '../../ButtonWrapper';
import theme from '../../../../../styles/theme/classic';
import { backgroundContainerClass } from '../../../../../helpers/constants/styling';
import { Spacing } from '../../../../../styles/theme/types';

interface ModalProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  backgroundColor?: string;
  hasPadding?: boolean;
  padding?: Spacing;
  hasCloseIcon?: boolean;
  closeIcon?: ReactNode;
  allowOnOutsideClickClose?: boolean;
  isLoading?: boolean;
  isOpen: boolean;
  onClose: CallbackFunction;
  children: ReactNode;
}

export default function Modal({
  backgroundColor = theme.palette.main.white,
  hasPadding = true,
  padding = 25,
  hasCloseIcon = false,
  closeIcon = <CloseModalIcon />,
  allowOnOutsideClickClose = true,
  isLoading = false,
  isOpen,
  onClose,
  children,
}: ModalProps) {
  const backdropRef = useRef<HTMLDivElement | null>(null);
  const [isBackdropMouseDown, setIsBackdropMouseDown] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (typeof window !== 'undefined' && window.document) {
        document.body.style.overflow = 'hidden';
      }
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpen]);

  const onBackdropMouseDown = (event: MouseEvent) => {
    setIsBackdropMouseDown(backdropRef.current === event.target);
  };

  const onBackdropMouseUp = (event: MouseEvent) => {
    const isBackdropMouseUp = backdropRef.current === event.target;
    if (
      isBackdropMouseDown &&
      isBackdropMouseUp &&
      allowOnOutsideClickClose &&
      !isLoading
    ) {
      onClose();
    }
  };

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <SC.Backdrop
      ref={backdropRef}
      isOpen={isOpen}
      onMouseDown={onBackdropMouseDown}
      onMouseUp={onBackdropMouseUp}
    >
      <SC.Container isOpen={isOpen} onClick={stopPropagation}>
        <SC.Background
          backgroundColor={backgroundColor}
          hasPadding={hasPadding}
          padding={padding}
          className={backgroundContainerClass}
        >
          {hasCloseIcon && (
            <SC.CloseIconRow>
              <ButtonWrapper
                onClick={isLoading ? undefined : onClose}
                disabled={isLoading}
              >
                <SC.IconContainer>{closeIcon}</SC.IconContainer>
              </ButtonWrapper>
            </SC.CloseIconRow>
          )}
          <SC.ChildrenContainer>{children}</SC.ChildrenContainer>
        </SC.Background>
      </SC.Container>
    </SC.Backdrop>
  );
}
