import { Currency } from 'helpers/constants/enums';
import { FileResponse } from './file';

export enum OnboardingStatus {
  AccountCreated = 'account_created',
  DisclaimerAccepted = 'disclaimer_accepted',
  RoleSet = 'role_set',
  UserDataSet = 'user_data_set',
  DomainsSet = 'domains_set',
  HobbiesSet = 'hobbies_set',
  ProfilePictureSet = 'profile_picture_set',
}

export enum AppRole {
  Admin = 'admin',
  Manager = 'manager',
  ContentCreator = 'content_creator',
  Consumer = 'consumer',
  ChildConsumer = 'child_consumer',
  Business = 'business',
}

export enum UserSetRole {
  Consumer = AppRole.Consumer,
  ContentCreator = AppRole.ContentCreator,
}

export interface ApiRequestBodySetUserRole {
  role: UserSetRole;
}

export interface ApiRequestBodySetUserData {
  firstName: string;
  lastName: string;
  birthdate: Date;
  nativeLanguageId: string;
  otherLanguageIds: string[];
  cityId: string;
  phone: string | null;
}

export interface ApiRequestUserData {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  nickname: null;
  description: null;
  phone: string;
  birthdate: string;
  role: {
    id: string;
    name: string;
  };
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
  origin: string;
  onboardingStatus: string;
  createdAt: string;
  profilePicture: FileResponse;
  slug: string;
}

export interface ApiSearchUsersResponse {
  results: ApiRequestUserData[];
  total: number;
}

export interface ApiResponseExplorer {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  birthdate: string;
  workPosition?: string;
  education?: string;
  biography?: string;
  memberSince: string;
  role: {
    id: string;
    name: string;
  };
  nativeLanguage: {
    id: string;
    name: string;
  };
  languages: {
    id: string;
    name: string;
  }[];
  profilePhoto: {
    id: string;
    path: string;
    name: string;
    extension: string;
    size: number;
    mimetype: string;
  };
  city: {
    id: string;
    name: string;
    county: {
      id: string;
      name: string;
      iso2: string;
      iso3: string;
    };
  };
  avgRating: null;
  hobbies: {
    id: string;
    name: string;
  }[];
  domains: {
    id: string;
    name: string;
  }[];
  packages: {
    id: string;
    title: string;
    presentationPhoto: {
      id: string;
      path: string;
      name: string;
      extension: string;
      size: number;
      mimetype: string;
    };
    type: string;
    price: number;
    discountPercent: number;
    description: string;
    location: string;
    city: { id: string; name: string };
    currency: Currency;
  }[];
  reviews: {
    uploader: {
      id: string;
      firstName: string;
      lastName: string;
      profilePhoto: FileResponse;
    };
    rating: number;
    description: string;
    profileImg: {
      id: string;
      path: string;
      name: string;
      extension: string;
      size: number;
      mimetype: string;
    };
    video: {
      id: string;
      path: string;
      name: string;
      extension: string;
      size: number;
      mimetype: string;
    };
  }[];
  consumerProfile: {
    jobRole: string;
    biography: string;
    education: string;
  };
}

export interface ApiResponseContentCreatorView {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  memberSince: string;
  cityName: string;
  avgRating: number;
  profilePicturePath: string;
  mentoredPeopleCount: number;
}

export interface ApiRequestBodySetUserDomains {
  domainIds: string[];
}

export interface ApiRequestBodySetUserHobbies {
  hobbyIds: string[];
}

export interface ApiRequestBodySetUserProfilePicture {
  profilePictureId: string;
}

export interface ApiRequestBodySetConsumerProfileData {
  jobRole: string;
  education: string;
  biography: string;
}

export interface Consumer {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  nickname: string | null;
  description: string | null;
  phone: string;
  birthdate: string;
  roleId: string;
  isEmailVerified: boolean;
  origin: string;
  onboardingStatus: string;
  metadata: {
    stripe: {
      customer: {
        created: number;
        customerId: string;
      };
    };
    consumerProfile: {
      jobRole: string;
      biography: string;
      education: string;
    };
  };
  createdAt: string;
  profilePicture: FileResponse;
}

export interface ApiRequestBodyRegisterReferralLinkClick {
  referralCode: string;
}

export interface ApiResponseBodyGetReferralAccount {
  id: string;
  referralCode: string;
  referralBalance: string;
  percentageGain: number;
  dueDate: string;
  viewCount: number;
  referredUserCount: number;
  packageConversionCount: number;
}

export interface ApiResponseReferralAccountHistory {
  id: string;
  appFee: string;
  percentageGain: number;
  packageId: string;
  packageTitle: string;
  packageFinalPrice: string;
  packageCurrency: string;
  createdAt: string;
  userReferralAccountId: string;
  buyer: {
    email: string;
    firstName: string;
    lastName: string;
  };
  package: {
    id: string;
    type: string;
    isPublic: boolean;
    isCanceled: boolean;
    isShadowBanned: boolean;
    isPermanent: boolean;
    title: string;
    description: string;
    startTime: string;
    endTime: string;
    additionalCosts: string;
    creatorDisclaimer: string;
    price: string;
    discountPercent: string;
    currency: string;
    purchaseDeadline: string;
    maxConsumersCount: number;
    gpsCoordinates: {
      lat: number;
      lng: number;
    };
    contentCreatorId: string;
    permanentPackageParentId: string;
    pinCodeValidatedAt: string;
    createdAt: string;
    status: string;
  };
}

export interface ApiResponseBodyReferralAccountHistory {
  results: ApiResponseReferralAccountHistory[];
  total: number;
}

export interface ApiResponseReferralAccountReferredUsers {
  email: string;
  createdAt: string;
  status: string;
}

export interface ApiResponseBodyReferralAccountReferredUsers {
  results: ApiResponseReferralAccountReferredUsers[];
  total: number;
}
