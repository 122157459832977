// eslint-disable-next-line import/no-cycle
import { pxToRem } from 'helpers/functions/styling';
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  palette: {
    main: {
      white: '#FFFFFF',
      yellow: '#EAC300',
      turquoise: '#00B0BA',
      marine: '#0082AA',
      darkMarine: '#00759A',
      cyan: '#00AFB9',
      blueGreen: '#26C3A7',
      green: '#06B317',
      red: '#EA4335',
      dark: '#1D1D1B',
      black: '#000000',
      veryLightGray: '#d9d9d9',
      lightGray: '#96AAAC',
      darkGray: '#3A3636',
    },
    button: {
      main: {
        yellow:
          'linear-gradient(96.05deg, rgba(234, 195, 0, 0.9) 33.2%, rgba(234, 195, 0, 0.828) 111.99%)',
        turquoise:
          'linear-gradient(90deg, rgba(0, 175, 185, 0.9) 0%, rgba(0, 175, 185, 0) 123.78%)',
        marine:
          'linear-gradient(89.64deg, rgba(0, 130, 170, 0.9) 0.21%, rgba(0, 175, 185, 0.9) 99.63%)',
        yellowTurquoise:
          'linear-gradient(95.43deg, rgba(234, 195, 0, 0.9) 33.76%, rgba(0, 175, 185, 0.9) 114.56%)',
        turquoiseYellow:
          'linear-gradient(91.96deg, rgba(0, 175, 185, 0.9) 1.69%, rgba(234, 195, 0, 0.9) 102.92%)',
      },
      hover: {
        yellow:
          'linear-gradient(96.05deg, rgba(234, 195, 0, 0.6) 33.2%, rgba(234, 195, 0, 0.528) 111.99%)',
        turquoise:
          'linear-gradient(90deg, rgba(0, 175, 185, 0.6) 0%, rgba(0, 175, 185, 0) 123.78%)',
        marine:
          'linear-gradient(89.64deg, rgba(0, 130, 170, 0.6) 0.21%, rgba(0, 175, 185, 0.6) 99.63%)',
        yellowTurquoise:
          'linear-gradient(95.43deg, rgba(234, 195, 0, 0.6) 33.76%, rgba(0, 175, 185, 0.6) 114.56%)',
        turquoiseYellow:
          'linear-gradient(91.96deg, rgba(0, 175, 185, 0.8) 1.69%, rgba(234, 195, 0, 0.8) 102.92%)',
      },
    },
    toast: {
      info: '#00759A',
      success: '#26C3A7',
      warning: '#EAC300',
      error: '#EA4335',
    },
    supporting: {
      border: '#DADADA',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.25rem',
    pxToRem,
  },
};

export default theme;
